import * as Sentry from "@sentry/nuxt"

const environment = process.env.NUXT_PUBLIC_APP_TARGET
const tracePropagationTargets = environment === 'production' ? [/^https:\/\/([^/]+\.)?augwa\.com/] : ["localhost", /^https?:\/\/([^/]+\.)?augwa\.dev/]

Sentry.init({
    dsn: process.env.NUXT_PUBLIC_SENTRY_DSN,
    environment,
    enabled: /^(true|1)$/i.test(process.env.NUXT_PUBLIC_SENTRY_ENABLED ?? ''),
    integrations: [Sentry.replayIntegration()],
    tracesSampleRate: Number(process.env.NUXT_PUBLIC_SENTRY_TRACES_SAMPLE_RATE),
    tracePropagationTargets,
    replaysSessionSampleRate: Number(process.env.NUXT_PUBLIC_SENTRY_REPLAYS_SESSION_SAMPLE_RATE),
    replaysOnErrorSampleRate: Number(process.env.NUXT_PUBLIC_SENTRY_REPLAYS_ON_ERROR_SAMPLE_RATE),
})
